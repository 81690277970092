var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-breadcrumbs',{staticClass:"grey lighten-2 white--text",attrs:{"items":_vm.breadcrumbs}}),_c('div',{staticClass:"pa-4"},[_c('div',{staticClass:"d-flex align-center justify-space-between mb-4"},[_c('p',{staticClass:"subtitle-1 ma-0"},[_vm._v("Lista de Produtos")]),(_vm.checkPermission('product.create'))?_c('v-btn',{attrs:{"small":"","depressed":"","color":"fmq_gray","dark":"","to":"/produto-criar"}},[_vm._v("Incluir novo produto")]):_vm._e()],1),_c('ProdutosTabela',{attrs:{"headers":_vm.headers,"produtos":_vm.produtos,"total":_vm.totalProdutos,"page":_vm.formData.page,"pageText":_vm.pageText,"loading":_vm.loading},on:{"handleFilter":_vm.filter,"excluir":_vm.excluirModal},scopedSlots:_vm._u([{key:"type",fn:function(){return [_c('v-autocomplete',{attrs:{"items":['FQM', 'Concorrente'],"hide-no-data":"","hide-selected":"","item-text":"label","item-value":"id","placeholder":"Busque pelo tipo","solo":"","flat":"","dense":"","hide-details":true,"clearable":true},on:{"input":function($event){_vm.buscar(_vm.formData);
            _vm.formData.page = 1;}},model:{value:(_vm.formData.type),callback:function ($$v) {_vm.$set(_vm.formData, "type", $$v)},expression:"formData.type"}})]},proxy:true},{key:"name",fn:function(){return [_c('v-text-field',{attrs:{"placeholder":"Busque pela marca","solo":"","flat":"","dense":"","hide-details":true,"clearable":true},on:{"input":function($event){_vm.buscar(_vm.formData);
            _vm.formData.page = 1;}},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}})]},proxy:true},{key:`line.name`,fn:function(){return [_c('v-autocomplete',{attrs:{"items":_vm.listLines,"hide-no-data":"","hide-selected":"","item-text":"label","item-value":"id","placeholder":"Busque pela linha","solo":"","flat":"","dense":"","hide-details":true,"clearable":true},on:{"input":function($event){_vm.buscar(_vm.formData);
            _vm.formData.page = 1;}},model:{value:(_vm.formData.linesId),callback:function ($$v) {_vm.$set(_vm.formData, "linesId", $$v)},expression:"formData.linesId"}})]},proxy:true},{key:`laboratory.name`,fn:function(){return [_c('v-autocomplete',{attrs:{"items":_vm.listLaboratories,"hide-no-data":"","hide-selected":"","item-text":"label","item-value":"id","placeholder":"Busque pelo laboratório","solo":"","flat":"","dense":"","hide-details":true,"clearable":true},on:{"input":function($event){_vm.buscar(_vm.formData);
            _vm.formData.page = 1;}},model:{value:(_vm.formData.laboratoriesId),callback:function ($$v) {_vm.$set(_vm.formData, "laboratoriesId", $$v)},expression:"formData.laboratoriesId"}})]},proxy:true},{key:"composition",fn:function(){return [_c('v-text-field',{attrs:{"placeholder":"Busque pela composição","solo":"","flat":"","dense":"","hide-details":true,"clearable":true},on:{"input":function($event){_vm.buscar(_vm.formData);
            _vm.formData.page = 1;}},model:{value:(_vm.formData.composition),callback:function ($$v) {_vm.$set(_vm.formData, "composition", $$v)},expression:"formData.composition"}})]},proxy:true},{key:`classification.name`,fn:function(){return [_c('v-autocomplete',{attrs:{"items":_vm.listClassifications,"hide-no-data":"","hide-selected":"","item-text":"label","item-value":"id","placeholder":"Busque pelo classificação","solo":"","flat":"","dense":"","hide-details":true,"clearable":true},on:{"input":function($event){_vm.buscar(_vm.formData);
            _vm.formData.page = 1;}},model:{value:(_vm.formData.classificationsId),callback:function ($$v) {_vm.$set(_vm.formData, "classificationsId", $$v)},expression:"formData.classificationsId"}})]},proxy:true},{key:"presentations",fn:function(){return [_c('v-text-field',{attrs:{"placeholder":"Busque pela apresentação","solo":"","flat":"","dense":"","hide-details":true,"clearable":true},on:{"input":function($event){_vm.buscar(_vm.formData);
            _vm.formData.page = 1;}},model:{value:(_vm.formData.presentations),callback:function ($$v) {_vm.$set(_vm.formData, "presentations", $$v)},expression:"formData.presentations"}})]},proxy:true},{key:`salesForces`,fn:function(){return [_c('v-autocomplete',{attrs:{"items":_vm.listSalesFroces,"hide-no-data":"","hide-selected":"","item-text":"label","item-value":"id","placeholder":"Busque pelo força de venda","solo":"","flat":"","dense":"","hide-details":true,"clearable":true},on:{"input":function($event){_vm.buscar(_vm.formData);
            _vm.formData.page = 1;}},model:{value:(_vm.formData.salesForcesId),callback:function ($$v) {_vm.$set(_vm.formData, "salesForcesId", $$v)},expression:"formData.salesForcesId"}})]},proxy:true},{key:`specialties`,fn:function(){return [_c('v-autocomplete',{attrs:{"items":_vm.listSpecialties,"hide-no-data":"","hide-selected":"","item-text":"label","item-value":"id","placeholder":"Busque pelo força de venda","solo":"","flat":"","dense":"","hide-details":true,"clearable":true},on:{"input":function($event){_vm.buscar(_vm.formData);
            _vm.formData.page = 1;}},model:{value:(_vm.formData.specialtiesId),callback:function ($$v) {_vm.$set(_vm.formData, "specialtiesId", $$v)},expression:"formData.specialtiesId"}})]},proxy:true},{key:`status`,fn:function(){return [_c('v-autocomplete',{attrs:{"items":['Rascunho', 'Publicado'],"hide-no-data":"","hide-selected":"","item-text":"label","item-value":"id","placeholder":"Busque pelo status","solo":"","flat":"","dense":"","hide-details":true,"clearable":true},on:{"input":function($event){_vm.buscar(_vm.formData);
            _vm.formData.page = 1;}},model:{value:(_vm.formData.status),callback:function ($$v) {_vm.$set(_vm.formData, "status", $$v)},expression:"formData.status"}})]},proxy:true},{key:"id",fn:function(){return [_c('div',{staticClass:"d-flex justify-start"},[_c('v-btn',{staticClass:"mr-2",attrs:{"outlined":"","x-small":"","fab":"","color":"red"},on:{"click":_vm.clear}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-btn',{attrs:{"outlined":"","x-small":"","fab":"","color":"blue"},on:{"click":_vm.search}},[_c('v-icon',[_vm._v("mdi-magnify")])],1)],1)]},proxy:true}],null,true)}),_c('AlertConfirmation',{attrs:{"dialog":_vm.dialog,"dialogMessage":_vm.dialogMessage},on:{"close":function($event){_vm.dialog = false},"accept":_vm.excluir}}),_c('AlertSuccess',{attrs:{"dialog":_vm.success,"dialogMessage":"Produto excluído com sucesso"},on:{"close":function($event){_vm.success = !_vm.success;
        _vm.buscar(_vm.formData);}}}),_c('AlertError',{attrs:{"alertError":_vm.error,"messageError":_vm.messageError}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
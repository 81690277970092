<template>
  <div>
    <v-breadcrumbs
      :items="breadcrumbs"
      class="grey lighten-2 white--text"
    ></v-breadcrumbs>
    <div class="pa-4">
      <div class="d-flex align-center justify-space-between mb-4">
        <p class="subtitle-1 ma-0">Lista de Produtos</p>
        <v-btn
          v-if="checkPermission('product.create')"
          small
          depressed
          color="fmq_gray"
          dark
          to="/produto-criar"
          >Incluir novo produto</v-btn
        >
      </div>
      <ProdutosTabela
        :headers="headers"
        :produtos="produtos"
        :total="totalProdutos"
        :page="formData.page"
        :pageText="pageText"
        :loading="loading"
        @handleFilter="filter"
        @excluir="excluirModal"
      >
        <template v-slot:type>
          <v-autocomplete
            v-model="formData.type"
            :items="['FQM', 'Concorrente']"
            hide-no-data
            hide-selected
            item-text="label"
            item-value="id"
            placeholder="Busque pelo tipo"
            solo
            flat
            dense
            :hide-details="true"
            :clearable="true"
            @input="
              buscar(formData);
              formData.page = 1;
            "
          ></v-autocomplete>
        </template>

        <template v-slot:name>
          <v-text-field
            v-model="formData.name"
            placeholder="Busque pela marca"
            solo
            flat
            dense
            :hide-details="true"
            @input="
              buscar(formData);
              formData.page = 1;
            "
            :clearable="true"
          >
          </v-text-field>
        </template>

        <template v-slot:[`line.name`]>
          <v-autocomplete
            v-model="formData.linesId"
            :items="listLines"
            hide-no-data
            hide-selected
            item-text="label"
            item-value="id"
            placeholder="Busque pela linha"
            solo
            flat
            dense
            :hide-details="true"
            :clearable="true"
            @input="
              buscar(formData);
              formData.page = 1;
            "
          ></v-autocomplete>
        </template>

        <template v-slot:[`laboratory.name`]>
          <v-autocomplete
            v-model="formData.laboratoriesId"
            :items="listLaboratories"
            hide-no-data
            hide-selected
            item-text="label"
            item-value="id"
            placeholder="Busque pelo laboratório"
            solo
            flat
            dense
            :hide-details="true"
            :clearable="true"
            @input="
              buscar(formData);
              formData.page = 1;
            "
          ></v-autocomplete>
        </template>

        <template v-slot:composition>
          <v-text-field
            v-model="formData.composition"
            placeholder="Busque pela composição"
            solo
            flat
            dense
            :hide-details="true"
            @input="
              buscar(formData);
              formData.page = 1;
            "
            :clearable="true"
          >
          </v-text-field>
        </template>

        <template v-slot:[`classification.name`]>
          <v-autocomplete
            v-model="formData.classificationsId"
            :items="listClassifications"
            hide-no-data
            hide-selected
            item-text="label"
            item-value="id"
            placeholder="Busque pelo classificação"
            solo
            flat
            dense
            :hide-details="true"
            :clearable="true"
            @input="
              buscar(formData);
              formData.page = 1;
            "
          ></v-autocomplete>
        </template>

        <template v-slot:presentations>
          <v-text-field
            v-model="formData.presentations"
            placeholder="Busque pela apresentação"
            solo
            flat
            dense
            :hide-details="true"
            @input="
              buscar(formData);
              formData.page = 1;
            "
            :clearable="true"
          >
          </v-text-field>
        </template>

        <template v-slot:[`salesForces`]>
          <v-autocomplete
            v-model="formData.salesForcesId"
            :items="listSalesFroces"
            hide-no-data
            hide-selected
            item-text="label"
            item-value="id"
            placeholder="Busque pelo força de venda"
            solo
            flat
            dense
            :hide-details="true"
            :clearable="true"
            @input="
              buscar(formData);
              formData.page = 1;
            "
          ></v-autocomplete>
        </template>

        <template v-slot:[`specialties`]>
          <v-autocomplete
            v-model="formData.specialtiesId"
            :items="listSpecialties"
            hide-no-data
            hide-selected
            item-text="label"
            item-value="id"
            placeholder="Busque pelo força de venda"
            solo
            flat
            dense
            :hide-details="true"
            :clearable="true"
            @input="
              buscar(formData);
              formData.page = 1;
            "
          ></v-autocomplete>
        </template>

        <template v-slot:[`status`]>
          <v-autocomplete
            v-model="formData.status"
            :items="['Rascunho', 'Publicado']"
            hide-no-data
            hide-selected
            item-text="label"
            item-value="id"
            placeholder="Busque pelo status"
            solo
            flat
            dense
            :hide-details="true"
            :clearable="true"
            @input="
              buscar(formData);
              formData.page = 1;
            "
          ></v-autocomplete>
        </template>

        <template v-slot:id>
          <div class="d-flex justify-start">
            <v-btn class="mr-2" outlined x-small fab color="red" @click="clear">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-btn outlined x-small fab color="blue" @click="search">
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </div>
        </template>
      </ProdutosTabela>
      <AlertConfirmation
        :dialog="dialog"
        :dialogMessage="dialogMessage"
        @close="dialog = false"
        @accept="excluir"
      />
      <AlertSuccess
        :dialog="success"
        dialogMessage="Produto excluído com sucesso"
        @close="
          success = !success;
          buscar(formData);
        "
      />
      <AlertError :alertError="error" :messageError="messageError" />
    </div>
  </div>
</template>

<script>
import { checkPermission } from "@/utils";
import ProdutosTabela from "@/components/produtos/ProdutosTabela.vue";
import { searchProduct, deleteProduct } from "@/services/product";
import { listLine } from "@/services/line";
import { listLaboratory } from "@/services/laboratory";
import { listClassification } from "@/services/classification.js";
import { listSaleForce } from "@/services/sale-force.js";
import { listSpecialty } from "@/services/specialty.js";
export default {
  name: "Produtos",
  components: { ProdutosTabela },
  data: () => ({
    breadcrumbs: [
      {
        text: "Produtos",
        disabled: true,
        to: "produtos",
      },
    ],
    headers: [
      { text: "Tipo", value: "type" },
      { text: "Marca", value: "name" },
      { text: "Linha", value: "line.name" },
      { text: "Laboratório", value: "laboratory.name" },
      { text: "Composição", value: "composition" },
      { text: "Classificação", value: "classification.name" },
      { text: "Apresentações", value: "presentations" },
      { text: "Força", value: "salesForces" },
      { text: "Especialidades", value: "specialties" },
      { text: "Status", value: "status" },
      { text: "Ações", value: "id", sortable: false },
    ],
    formData: {
      sort: null,
      perPage: 20,
      page: 1,
      name: null,
      type: null,
      linesId: null,
      laboratoriesId: null,
      composition: null,
      classificationsId: null,
      presentations: null,
      salesForcesId: null,
      specialtiesId: null,
      status: null,
    },
    produtos: [],
    totalProdutos: null,
    pageText: null,
    loading: false,
    listLines: [],
    listLaboratories: [],
    listClassifications: [],
    listSalesFroces: [],
    listSpecialties: [],
    dialog: false,
    error: false,
    success: false,
    messageError: null,
    dialogMessage: "",
  }),
  created() {
    this.$store.dispatch("setDuplicateProduct", null);
    if (this.$store.state.searchProductHistory.length) {
      this.formData = { ...this.$store.state.searchProductHistory[0] };
    }
    this.buscar(this.formData);
    this.listarLinhas();
    this.listarLaboratorios();
    this.listarClassificacao();
    this.listarForcaDeVemdas();
    this.listarEspecialidades();
  },
  methods: {
    checkPermission,
    async buscar(payload) {
      this.produtos = [];
      this.$store.dispatch("setSearchProductHistory", payload);
      try {
        this.loading = true;
        const resp = await searchProduct(payload);
        this.produtos = resp.data;
        this.totalProdutos = resp.meta.total;
        this.formData.page = resp.meta.currentPage;
        this.pageText = `${resp.meta.from}-${resp.meta.to} de ${resp.meta.total}`;
      } catch (e) {
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
    async excluir() {
      this.error = false;
      this.sucess = false;
      try {
        await deleteProduct(this.itemId).then(() => {
          this.dialog = false;
          this.success = true;
        });
      } catch (e) {
        this.dialog = false;
        this.error = true;
        this.messageError = e.response.data.message;
      }
    },
    excluirModal(item) {
      this.dialogMessage = `Tem certeza que deseja excluir ${item.name}?`;
      this.dialog = true;
      this.itemId = item.id;
    },
    async listarLinhas() {
      const resp = await listLine();
      this.listLines = resp.data;
    },
    async listarLaboratorios() {
      const resp = await listLaboratory();
      this.listLaboratories = resp.data;
    },
    async listarClassificacao() {
      const resp = await listClassification();
      this.listClassifications = resp.data;
    },
    async listarForcaDeVemdas() {
      const resp = await listSaleForce();
      this.listSalesFroces = resp.data;
    },
    async listarEspecialidades() {
      const resp = await listSpecialty();
      this.listSpecialties = resp.data;
    },
    filter(event) {
      this.$store.dispatch("setSearchProductHistory", this.formData);
      if (event.orderBy) {
        this.formData.sort = event.orderBy + event.orderSorted;
      } else {
        this.formData.sort = null;
      }
      this.formData.perPage = event.perPage;
      this.formData.page = event.page;
      this.buscar(this.formData);
    },
    clear() {
      this.$store.dispatch("setSearchProductHistory", {});
      (this.formData.name = null),
        (this.formData.type = null),
        (this.formData.linesId = null),
        (this.formData.laboratoriesId = null),
        (this.formData.composition = null),
        (this.formData.classificationsId = null),
        (this.formData.presentations = null),
        (this.formData.salesForcesId = null),
        (this.formData.specialtiesId = null),
        (this.formData.page = 1);
      this.buscar(this.formData);
    },
    search() {
      this.formData.page = 1;
      this.buscar(this.formData);
    },
  },
};
</script>
